<template>
  <div
    :id="`wrapper_${index}`"
    class="relative flex min-h-[50vh] w-screen items-center overflow-hidden xl:h-screen"
  >
    <div class="absolute z-20 w-screen space-y-4 divide-y divide-white p-10 xl:left-1/2 xl:w-[50vw]">
      <h2 class="text-hero-header text-white md:text-desktop-hero-header">
        What's On
      </h2>
      <div
        v-for="(link, index) in slice.primary.links"
        :key="index"
        ref="links"
      >
        <NuxtLink
          :to="prismic.asLink(link.link) || ''"
          class=""
        >
          <h2
            class="text-hero-header text-white after:relative after:left-[50px] after:inline-block after:w-[4vw] after:transition-all after:content-[url(~/assets/svgs/button_arrow_white.svg)] hover:after:left-[100px] md:text-desktop-hero-header"
          >
            {{ link.label }}
          </h2>
        </NuxtLink>
      </div>
    </div>

    <!-- Text Protect -->
    <div class="absolute right-0 z-10 h-full w-screen bg-gradient-to-l from-charcoal-100 opacity-80 mix-blend-multiply" />

    <PrismicImage
      v-if="slice.variation === 'default'"
      :field="slice.primary.image"
      class="min-h-[50vh] w-screen object-cover xl:h-screen"
    />
  </div>
</template>

<script setup lang="ts">
import type { Content } from "@prismicio/client";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const links = ref([]);

const prismic = usePrismic();
// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.WhatsOnSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ]),
);

onMounted(() => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: `#wrapper_${props.index.toString()}`,
      start: "top center",
      end: "bottom bottom",
      scrub: true,
    },
  });

  tl.from(links.value, { x: "200", opacity: 0, duration: 0.8, stagger: 0.2 });
});
</script>
